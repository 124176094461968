@import './mixins/media-query.scss';

$base-font-size: 16px;

body {
  @include respond-to(small) {
    font-size: $base-font-size;
  }
  @include respond-to(medium) {
    font-size: $base-font-size * 0.85;
  }
  @include respond-to(large) {
    font-size: $base-font-size * 1.1;
  }
}

