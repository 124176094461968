@import 'src/assets/styles/mixins/flexbox.scss';

.footer {
  @include flexbox($justifyContent: space-between, $alignItems: center);
  font-family: 'Roboto';
  font-size: 13px;
  line-height: 15.23px;
  color: var(--silver-500);
  width: 100%;
  padding: 12px 44px 12px 48px;
  cursor: default;

  .left, .copy {
    display: flex;
    align-items: center;
  }

  .copy button {
    margin-left: 5px !important;
    padding: 5px !important;
  }

  .left > p, .right > a {
    position: relative;
    &:not(:last-child) {
      padding-right: 15px;
      &::after {
        content: "|";
        position: absolute;
        right: 0;
      }
    }
    &:not(:first-child) {
      padding-left: 15px;
    }
  }
}