@import 'src/assets/styles/mixins/flexbox.scss';

.avatar {
  @include flexbox($alignItems: center);

  .user {
    @include flexbox($direction: column, $placeItems: flex-end);

    .name {
      color: var(--green);
      font-size: 1rem;
      font-weight: 600;
    }
  
    .role {
      color: var(--silver-500);
      font-size: 0.8rem;
    }
  }

  .icon {
    @include flexbox($alignItems: center, $justifyContent: center);
    font-size: 20px;
    width: 40px;
    height: 40px;
    color: var(--silver-700);
    background-color: var(--silver-300);
    padding: 5px;
    border-radius: 100%;
  }
}