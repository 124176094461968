.tabWrapper {
  position: relative;

  tbody tr {
    cursor: pointer;
  }
}
.number {
  font-weight: 400;
  font-size: 1rem;
  color: var(--silver-500);
  position: absolute;
  top: -60px;
  right: 0;
  user-select: none;
}

