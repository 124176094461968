@import 'src/assets/styles/mixins/title.scss';
@import 'src/assets/styles/mixins/flexbox.scss';


.dialog {
  @include flexbox($direction: column);
  min-height: 200px;

  h3 {
    @include title($fs: 2rem, $lh: 2.5rem);
    margin-bottom: 20px;
  }
  
  .content {
    flex: 1;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--silver-500);
  }
  
  footer {
    @include flexbox($justifyContent: flex-end);
    gap: 20px;
  } 
}
