@import 'src/assets/styles/mixins/flexbox.scss';

.wrapper {
  @include flexbox($direction: column, $alignItems: center, $justifyContent: center);
  gap: 20px;
  background-color: var(--silver-150);
  border: 2px dashed var(--silver-300);
  min-height: calc(70vh - 192px);
  padding: 50px;

  .content {
    @include flexbox($direction: column, $alignItems: center, $justifyContent: center);
    gap: 20px;
    max-width: 600px;
    color: var(--silver-500);

    .msg {
      text-align: center;
      cursor: default;
      & > span {
        display: block;
      }
    }
  }
}