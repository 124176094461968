@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == medium {
    @media (min-width: 992px) and (min-width: 1199px) {
      @content;
    }
  } @else if $breakpoint == large {
    @media (min-width: 1200px) {
      @content;
    }
  }
}