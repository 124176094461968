@import 'src/assets/styles/mixins/title.scss';
@import 'src/assets/styles/mixins/flexbox.scss';

.wrapper {
  width: 100%;
  
  .form {
    @include flexbox($direction: column);

    .title {
      @include title($fs: 2.5rem, $lh: 3.25rem);
      margin: 30px 0 10px 0;
    }

    .section {
      position: relative;
      padding: 20px 0 40px 0;
  
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--silver-300);
      }

      .subtitle {
        @include title($ff: Calibre, $fs: 1.1rem, $lh: 1.3rem);
      }
      
      .mb10 {
        margin-bottom: 10px;
      }

      .switch {
        @include flexbox($alignItems: center);
        gap: 10px;
      }
    }

    .content {
      @include flexbox($direction: column);
      gap: 30px;
      padding-top: 20px;
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}
