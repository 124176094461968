@import './fonts.scss';
@import './font-size.scss';
@import './mixins/flexbox.scss';

:root {
  --border-radius: 12px;
  --black: #222;
  --white: #fff;
  --green: #003F2D;
  --accent-green: #17E88F;
  --dark-green: #012A2D;
  --silver-100: #F5F7F7;
  --silver-150: #F5F5F5;
  --silver-200: #E5E9EB;
  --silver-300: #CAD1D3;
  --silver-500: #778F9C;
  --silver-700: #435254;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: 'Calibre';
  height: 100vh;
  height: 100dvh;
  @include flexbox($direction: column);

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    main {
      flex: 1;
      margin: 50px 44px 48px 48px;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}