.wrapper {
  .tab {
    width: 160px;
    height: 48px;
    margin-bottom: 20px;
  }

  .head {
    height: 40px;
  }

  .row {
    margin: 6px 0;
    height: 72px;
  }
} 