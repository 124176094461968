/* Calibre */
@font-face{
  font-family:"Calibre";
  font-display:swap;
  font-weight:400;
  src:url('../fonts/calibre/calibre-web-regular.woff2') format("woff2");
}
@font-face{
  font-family:"Calibre";
  font-display:swap;
  font-weight:500;
  src:url('../fonts/calibre/calibre-web-medium.woff2') format("woff2");
}
@font-face{
  font-family:"Calibre";
  font-display:swap;
  font-weight:600;
  src:url('../fonts/calibre/calibre-web-semibold.woff2') format("woff2");
}
@font-face{
  font-family:"Calibre";
  font-display:swap;
  font-weight:700;
  src:url('../fonts/calibre/calibre-web-bold.woff2') format("woff2");
}

/* Financier */
@font-face{
  font-family:"FinancierDisplay";
  font-display:swap;
  font-weight:600;
  src:url('../fonts/FinancierDisplay/financier-display-web-semibold.woff2') format("woff2");
}

/* Roboto */
@font-face{
  font-family:"Roboto";
  font-display:swap;
  font-weight:400;
  src:url('../fonts/Roboto/Roboto-Regular.woff2') format("woff2");
}