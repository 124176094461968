@import 'src/assets/styles/mixins/flexbox.scss';
@import 'src/assets/styles/mixins/media-query.scss';

.header {
  width: 100%;
  height: 192px;
  background-color: var(--silver-200);
  border-bottom: 1px solid var(--silver-300);

  .content {
    @include flexbox($direction: column, $justifyContent: space-between);
    gap: 30px;
    margin: 0 44px 0 48px;
    height: inherit;
    padding: 12px 0 25px 0;

    .top {
      @include flexbox($justifyContent: space-between, $alignItems: center);
      height: 56px;
      
      .blockWrapper {
        @include flexbox($alignItems: center, $justifyContent: space-between);
        transform: translateX(8px);
      }
      
      .logo {
        @include flexbox($alignItems: center);
        gap: 5px;
        width: min-content;
        color: var(--green);
        font-family: 'FinancierDisplay';
        font-size: 12px;
        line-height: 12px;
  
        figcaption {
          @include respond-to(small) {
            display: none;
          }
        }
      }
    }

    .bottom {
      @include flexbox($alignItems: center, $justifyContent: space-between);
      gap: 40px;

      .title {
        font-family: 'FinancierDisplay';
        font-size: 2.5rem;
        overflow: hidden;
        color: var(--green);
        cursor: default;
      }

      .action {
        @include flexbox($alignItems: center);
        gap: 10px;
        flex-shrink: 0;
      }
    }
  }
}
