@mixin title(
  $ff: 'FinancierDisplay',
  $fs: 1rem,
  $fw: 600,
  $lh: 1rem,
  $color: var(--dark-green)
) {
    font-family: $ff;
    font-size: $fs;
    font-weight: $fw;
    line-height: $lh;
    color: $color;
    cursor: default;
}